define('ember-pikaday/components/pikaday-inputless', ['exports', 'ember-pikaday/mixins/pikaday', 'ember-pikaday/templates/pikaday-inputless'], function (exports, _emberPikadayMixinsPikaday, _emberPikadayTemplatesPikadayInputless) {
  'use strict';

  exports['default'] = Ember.Component.extend(_emberPikadayMixinsPikaday['default'], {
    layout: _emberPikadayTemplatesPikadayInputless['default'],

    didInsertElement: function didInsertElement() {
      this.set('field', this.$('.ember-pikaday-input')[0]);
      this.set('pikadayContainer', this.$('.ember-pikaday-container')[0]);
      this.setupPikaday();
    },
    onPikadayOpen: function onPikadayOpen() {},
    onPikadayClose: function onPikadayClose() {}
  });
});