define("ember-uuid/index", ["exports", "ember-uuid/utils/uuid-generator"], function (exports, _emberUuidUtilsUuidGenerator) {
  "use strict";

  Object.defineProperty(exports, "v1", {
    enumerable: true,
    get: function get() {
      return _emberUuidUtilsUuidGenerator.v1;
    }
  });
  Object.defineProperty(exports, "v4", {
    enumerable: true,
    get: function get() {
      return _emberUuidUtilsUuidGenerator.v4;
    }
  });
  Object.defineProperty(exports, "parse", {
    enumerable: true,
    get: function get() {
      return _emberUuidUtilsUuidGenerator.parse;
    }
  });
  Object.defineProperty(exports, "unparse", {
    enumerable: true,
    get: function get() {
      return _emberUuidUtilsUuidGenerator.unparse;
    }
  });
});