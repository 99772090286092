define('ember-highcharts/utils/chart-data', ['exports'], function (exports) {
  'use strict';

  exports.getSeriesMap = getSeriesMap;
  exports.getSeriesChanges = getSeriesChanges;

  function getSeriesMap(seriesGroup) {
    var seriesMap = seriesGroup.reduce(function (seriesMap, seriesItem) {
      seriesMap[seriesItem.name] = seriesItem;
      return seriesMap;
    }, {});

    return seriesMap;
  }

  function getSeriesChanges(contentSeries, series) {
    var updatedKeys = Object.keys(contentSeries).filter(function (key) {
      var isValidKey = key !== 'data' && key.charAt(0) !== '_';
      var isValidType = ['object', 'function'].indexOf(typeof contentSeries[key]) === -1;
      var isTheSame = contentSeries[key] === series[key];

      return isValidKey && isValidType && !isTheSame;
    });

    // returns a list of updated keys
    return updatedKeys;
  }
});